<template>
  <BaseTable list class="py-0 overflow-hidden">
    <TableHead class="sticky top-0 z-[2]">
      <TableHeadCell class="sticky right-0 z-[1] bg-white">
        <div class="px-5 flex flex-1">
          <div
            class="whitespace-nowrap text-sm font-simplerRegular text-secondary-900">
            תלמידים
          </div>
        </div>
      </TableHeadCell>
      <TableHeadCell
        v-for="{ stage, locked } in enrollmentSummary"
        :key="stage.id">
        <div
          class="flex flex-col items-center justify-center py-2 h-25 w-36 px-5 gap-1 text-sm">
          <div
            class="p-2 rounded-full mb-1"
            :class="{
              'cursor-default bg-primary-200 ': !locked,
              'bg-secondary-300 cursor-pointer': locked,
            }"
            @click="locked && $emit('unlockStage', stage.id)">
            <Unlock
              v-if="!locked"
              class="h-3 w-3 fill-primary-200 stroke-primary"/>
            <Lock v-else class="h-3 w-3 fill-secondary-300 stroke-[#B0BAC8]" />
          </div>
          <div
            :title="stage.title"
            class="whitespace-nowrap text-xs font-simplerLight text-center leading-1 h-5">
            {{ stage.title }}
          </div>
        </div>
      </TableHeadCell>
    </TableHead>
    <TableBody>
      <TableRow v-for="student in sortedStudents" :key="student.id">
        <TableCell class="sticky right-0 z-[1] bg-white">
          {{ student.fullName }}
        </TableCell>
        <TableCell v-for="{ stage } in enrollmentSummary" :key="stage.id">
          <div
            v-if="getSubmissionStatus(student.id, stage.id) === 'in_progress'"
            class="m-auto flex items-center justify-center h-9 w-9 rounded-full bg-secondary-200">
            <span class="text-primary-700 text-xs">--</span>
          </div>
          <RouterLink
            v-else
            class="flex justify-center items-center font-simplerBold text-xs text-primary-700 cursor-pointer"
            :to="{
              name: 'review-project',
              params: { group, project, stage: stage.id },
              query: { student: student.id },
            }">
            {{
              getSubmissionStatus(student.id, stage.id) === 'awaiting_review'
                ? 'לבדיקה >'
                : 'נבדק'
            }}
          </RouterLink>
        </TableCell>
      </TableRow>
    </TableBody>
  </BaseTable>
</template>

<script setup lang="ts">
import { computed, PropType, ComputedRef } from 'vue';
import { get } from 'lodash';
import {
  BaseTable,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from '@nwire/amit-design-system';
import type {
  IStudent,
  IProjectEnrollment,
  IProjectEnrollmentStage,
} from '/@/app/models/interfaces';
import { Lock, Unlock } from 'lucide-vue-next';
import { RouteParams, useRoute } from 'vue-router';
import { sortStudents } from '/@/utils';

const props = defineProps({
  students: {
    type: Array as PropType<IStudent[]>,
    default: () => [],
  },
  enrollments: {
    type: Array as PropType<IProjectEnrollment[]>,
    default: () => [],
  },
});
defineEmits(['unlockStage']);
const route = useRoute();
const { group, project }: RouteParams | { group: any; project: any } =
  route.params;

const enrollmentSummary: ComputedRef<IProjectEnrollmentStage[]> = computed(() =>
  get(props.enrollments, '[0].summary', []),
);
const sortedStudents = computed(() => sortStudents(props.students));

const getSubmissionStatus = (student, stage) => {
  const studentEnrollment = props.enrollments?.find(
    enrollment => enrollment.student === student,
  );
  const enrollmentSummaryStage = studentEnrollment?.summary?.find(
    item => item.stage.id === stage,
  );
  return enrollmentSummaryStage?.status ?? '';
};
</script>
